export enum Environment {
	Production = 'Production',
	Staging = 'Staging',
	Acceptance = 'Acceptance',
	Local = 'Local',
}

export enum Template {
	mobile = 'mobile',
	desktop = 'desktop',
}

export enum StorageKey {
	user = 'user',
	employee = 'employee',
	menu = 'menu',
	language = 'language',
	partner = 'partner',
	client = 'client',
	workOrderMetadata = 'workOrderMetadata',
	hasValidatedRegions = 'hasValidatedRegions',
	hasReceivedExtraInfo = 'hasReceivedExtraInfo',
	hasSeenNotificationPreferences = 'hasSeenNotificationPreferences',
	modalsExpiredIdCardNumberOfSkips = 'modals.expiredIdCard.numberOfSkips',
	modalsExpiredIdCardLastSeenReminderDate = 'modals.expiredIdCard.lastSeenReminderDate',
	modalsExpiredStudentCardLastSeenReminderDate = 'modals.expiredStudentCard.lastSeenReminderDate',
}

export enum TalentTags {
	Android = 'android',
	Mail = 'mail',
	Ios = 'ios',
	Mac = 'mac',
	Networks = 'networks',
	Windows = 'windows',
}

export enum NavigationType {
	modal,
	popover,
	page,
}

export enum ErrorType {
	Toast = 'toast',
	PermanentToast = 'permanentToast',
	Component = 'component',
}

export enum NavigationDataKey {
	employee = 'employee',
	client = 'client',
	calendarEvent = 'calendarEvent',
	appointment = 'appointment',
	partner = 'partner',
	dateRange = 'dateRange',
	appointmentForm = 'appointmentForm',
	digicheque = 'digicheque',
	discount = 'discount',
	branch = 'branch',
	updatingPartner = 'updatingPartner',
	closeEvent = 'closeEvent',
	requirement = 'requirement',
	date = 'date',
	event = 'event',
	action = 'action',
	isConsultant = 'isConsultant',
	appointments = 'appointments',
	clientId = 'clientId',
	isCurrentUserData = 'isCurrentUserData',
	employeeId = 'employeeId',
	region = 'region',
	selectedZipcodes = 'selectedZipcodes',
	stateChanges = 'stateChanges',
	selectedChange = 'selectedChange',
	clickEvent = 'clickEvent',
	appointmentAction = 'appointmentAction',
	editingAppointment = 'editingAppointment',
	workOrderCalculation = 'workOrderCalculation',
	metrics = 'metrics',
	paymentRedirect = 'paymentRedirect',
	regionName = 'regionName',
	regionAddress = 'regionAddress',
	regionInit = 'regionInit',
	appointmentRequest = 'appointmentRequest',
	appointmentRequestId = 'appointmentRequestId',
	problem = 'problem',
	zipcode = 'zipcode',
	executions = 'executions',
	taskName = 'taskName',
	coordinates = 'coordinates',
	address = 'address',
	qr = 'qr',
	callback = 'callback',
	amount = 'amount',
	Topic = 'Topic',
	partnerId = 'partnerId',
	packageTemplate = 'packageTemplate',
	nonPayment = 'nonPayment',
	payrolling = 'payrolling',
	topics = 'topics',
	mandatoryPasswordChange = 'mandatoryPasswordChange',
	digipoint = 'digipoint',
	digipointEvent = 'digipointEvent',
	digipointReservation = 'digipointReservation',
	step = 'step',
	bookPartnerAppointmentState = 'bookPartnerAppointmentState',
	acquisition = 'acquisition',
	updatingAcquisition = 'updatingAcquisition',
	partnerUser = 'partnerUser',
	isBooking = 'isBooking',
	showCloseButton = 'showCloseButton',
	workOrder = 'workOrder',
	digipointId = 'digipointId',
	digipointInterval = 'digipointInterval',
	workOrderTimeEntry = 'workOrderTimeEntry',
	digipointForm = 'digipointForm',
	calendarEventReservation = 'calendarEventReservation',
	workBlock = 'workBlock',
	appointmentRating = 'appointmentRating',
	digipointRating = 'digipointRating',
	digipointEmployeeRating = 'digipointEmployeeRating',
	digipointAddress = 'digipointAddress',
	creditBatch = 'creditBatch',
	workOrderId = 'workOrderId',
	metadataForm = 'metadataForm',
	workOrderClosingStatus = 'workOrderClosingStatus',
	modalAction = 'modalAction',
	description = 'description',
}

export enum AppointmentStatus {
	FINISHED = 'finished',
	CANCELLED = 'cancelled',
	TODO = 'todo',
	UNKNOWN = 'unknown',
	QUEUE = 'queue',
	UNFINISHED = 'unfinished',
}

export enum ECalendarEventReservationStatus {
	ACTIVE = 'active',
	EXPIRED = 'expired',
	BOOKED = 'booked',
}

export enum Gender {
	Male = 'male',
	Female = 'female',
	X = 'x',
}

export enum ROLE {
	CONSULTANT = 'Consultant',
	DISPATCH = 'Dispatch',
	ADMIN = 'Admin',
	SUPER_ADMIN = 'SuperAdmin',
	PARTNER = 'Partner',
	/** @deprecated Use partner instead */
	SOCIAL_PARTNER = 'SocialPartner',
	/** @deprecated No longer exists */
	CLIENT = 'Client',
}

export enum Action {
	Create = 'create',
	Update = 'update',
	Delete = 'delete',
	View = 'view',
}

export enum ModalAction {
	UPDATED = 'updated',
	CREATED = 'created',
	DELETED = 'deleted',
	FINISHED = 'finished',
}

export enum InputMode {
	TEXT = 'text',
	DECIMAL = 'decimal',
	NUMERIC = 'numeric',
	TEL = 'tel',
	SEARCH = 'search',
	EMAIL = 'email',
	URL = 'url',
}

export enum AppointmentType {
	Remote = 'REMOTE',
	OnLocation = 'ON_LOCATION',
}

export enum ZipcodeState {
	CLOSE_TO_ACTIVE = 'closeToActive',
	ACTIVE = 'active',
	REMOTE = 'remote',
}

export enum FileType {
	Pdf = 'application/pdf',
	Image = 'image/*',
}

export enum MapProvinces {
	antwerpen = 'antwerpen',
	brussel = 'brussel',
	oostVlaanderen = 'oost-vlaanderen',
	westVlaanderen = 'west-vlaanderen',
	vlaamsBrabant = 'vlaams-brabant',
	waalsBrabant = 'waals-brabant',
	namen = 'namen',
	luxemburg = 'luxemburg',
	limburg = 'limburg',
	luik = 'luik',
	henegouwen = 'henegouwen',
}

export enum Province {
	Antwerpen = 'Antwerpen',
	Brussel = 'Brussels Hoofdstedelijk Gewest',
	Limburg = 'Limburg',
	Henegouwen = 'Henegouwen',
	Luik = 'Luik',
	Luxemburg = 'Luxemburg',
	Namen = 'Namen',
	OostVlaanderen = 'Oost-Vlaanderen',
	VlaamsBrabant = 'Vlaams-Brabant',
	WaalsBrabant = 'Waals-Brabant',
	WestVlaanderen = 'West-Vlaanderen',
}

export const enum MapRegionType {
	province = 'province',
	municipality = 'municipality',
}

export enum EventInterval {
	Daily = 'daily',
	BusinessDays = 'business-days',
	Weekly = 'weekly',
	Monthly = 'monthly',
	MonthlyDay = 'monthly-day',
	Yearly = 'yearly',
	'One-Time' = 'one-time',
	Custom = 'custom',
}

export enum CustomIntervalType {
	Days = 'days',
	BusinessDays = 'business-days',
	Weeks = 'weeks',
	Months = 'months',
	Years = 'years',
}

export enum DigipointType {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
}

export enum DiscountType {
	Generic = 'generic',
	Digicheque = 'digicheque',
	Referral = 'referral',
}

export enum DiscountValueType {
	Percentage = 'percentage',
	Fixed = 'fixed',
}

export enum ValidationErrorLevel {
	warning = 'warning',
	error = 'error',
}

export enum CalendarEventType {
	Workblock = 'workblock',
	Appointment = 'appointment',
	Digipoint = 'digipoint',
}

export enum WorkOrderType {
	Appointment = 'appointment',
	Remote = 'remote',
	Digipoint = 'digipoint',
}
