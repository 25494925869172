import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';
import { ValidationService } from '@shared-services/validation.service';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
	selector: 'wrong-version-error-component',
	templateUrl: 'wrong-version-error.component.html',
	styleUrls: ['./wrong-version-error.component.scss'],
})
export class WrongVersionErrorComponent implements OnInit {
	public os: 'android' | 'ios' | 'web';

	constructor(
		private readonly validationService: ValidationService,
		private readonly modalController: ModalController
	) {}

	public ngOnInit(): void {
		this.os = this.validationService.isOnNativeAndroid()
			? 'android'
			: this.validationService.isOnNativeiOS()
				? 'ios'
				: 'web';
	}

	public goToPlayStore(): void {
		void Browser.open({ url: 'https://play.google.com/store/apps/details?id=be.beego.app' });
		this.modalController.dismiss();
	}

	public async goToTestFlight(): Promise<void> {
		void AppLauncher.openUrl({ url: `itms-beta://beta.itunes.apple.com/v1/app/mcBArBqD` }).catch();
		this.modalController.dismiss();
	}

	public reloadPage(): void {
		location.reload();
	}
}
